import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from "gatsby-image";
import { theme } from "../styles";
import styled from '@emotion/styled'
import { Wrapper } from '../components'
import website from '../../config/website'

import {
  CatalogItem,
  CatalogHeader,
} from '../components'
import {
  CatalogWrapper
} from '../templates/catalogItemStyle'

const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: "Lato" !important;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2.4rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 0 0 2rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;

const Head = styled.div`
  position: relative;
  margin-bottom: 1.35rem;
`;

const Hero = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > div {
    width: 50%;
    &:first-of-type {
      padding-right: 1rem;
    }
    &:last-of-type {
      padding-left: 1rem;
    }
    p {
      margin-bottom: 1.35rem;
      font-size: 1.1rem;
      color: #6d6d6d;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    > div {
      width: 100%;
      &:first-of-type {
        padding-right: 0;
      }
      &:last-of-type {
        padding-left: 0;
      }
      p {
        &:first-of-type {
          padding-top: 1.35rem;
        }
      }
    }
  }
`;

const MarsMMXXIII = ({ data }) => {

  const context = { slug: "exhibition", parent: "" };
  const artworks = data.artworks.nodes;
  
  return (
    <React.Fragment>
      <Helmet title={`Disch Rémy - La lettre`}>
        <link rel="stylesheet" type="text/css" href="https://unpkg.com/xzoom/dist/xzoom.css" media="all" />
        <script defer data-domain="dischr.com" src="https://plausible.io/js/script.js"></script>
      </Helmet>
      <CatalogHeader {...context} letter />
      <IndexWrapper id={website.skipNavId} style={{ paddingBottom: 0}}>
        <Inner>
          <Head>
            <Img
              fixed={data.banner.childImageSharp.fixed}
              placeholderStyle={{
                filter: "grayscale(100%) contrast(100%)",
                imageRendering: "pixelated",
                opacity: 0,
              }}
            />
            <Hero style={{ position: 'absolute', bottom: 0, left: '1rem' }}>
              <div style={{ width: '100%' }}>
                <h3 style={{ color: '#fff', opacity: .8 }}>
                  <small>Mars 2023</small><br />
                </h3>
              </div>
            </Hero>
          </Head>
          <Hero>
            <div style={{ width: '100%', paddingTop: '2rem', paddingBottom: '2rem', paddingLeft: 0, paddingRight: 0 }}>
              <h3><strong>Nouveau partenariat</strong></h3>
              <p>
                Depuis plusieurs mois, le travail de Rémy est très remarqué et nous recevons beaucoup de demandes de partenariats.
              </p>

              <p>
                Nous avons notamment été contactés par de nombreuses sociétés vendant des reproductions d'oeuvres d'art.<br />
                L'une d'elle s'est détachée par sa qualité de travail, son sérieux et son rayonnement à l'international.<br />
                Ceci nous a amené à entamer une reflexion sur le merchandising autour du travail de Rémy.
              </p>
              
              <p>
                En effet, un public grandissant souhaite acquérir des oeuvres, toutefois, sa cote ayant été réévaluée à la hausse, 
                cela implique un engagement financier souvent important.<br />
                {` `}(<a className="regular-link" href={`https://disch.guidarts.com/`} target="_blank">
                  voir le dictionnaire officiel des artistes contemporains cotés en partenariat avec la maison Drouot
                </a>)
              </p>

              <p>
                Après concertation avec Rémy, nous avons donc décidé de signer un accord de partenariat avec la société Américaine 
                {` `}<a className="regular-link" href={`https://www.icanvas.com/canvas-art-prints/artist/remy-disch`} target="_blank"><strong>iCanvas</strong></a>.{` `} 
                Cette nouvelle collaboration nous permettra de proposer des copies de haute qualité des oeuvres de Rémy à un large public et à des prix très abordables.
              </p>

              <p>
                Une quarantaine d'oeuvres du catalogue ont été sélectionnées par {` `}<a className="regular-link" href={`https://www.icanvas.com/canvas-art-prints/artist/remy-disch`} target="_blank"><strong>iCanvas</strong></a>{` `} 
                dont vous avez fait l'acquisition pour certaines. 
                Pour les autres vous pouvez toujours acquérir les oeuvres originales sur le
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog`} target="_blank">catalogue 2021-2022</a>{` `} et sur le
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog/fonds-d-atelier`} target="_blank">catalogue fonds d'atelier</a>.
              </p>

              <p>
                C'est une belle nouvelle qui je l'espère saura vous satisfaire autant que nous avons mis d'énergie à rendre cela possible.
              </p>

              <p>
                Beau printemps, <br />
                Merci.
              </p>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>
      
      <IndexWrapper id={website.skipNavId} style={{paddingTop: 0}}>
        <Inner>
          <Hero>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}><a 
                href="https://www.icanvas.com/canvas-art-prints/artist/remy-disch" 
                target="_blank"
                style={{
                  border: '1px solid #b4cfb0',
                  background: '#b4cfb0',
                  color: '#f2f2f2',
                  fontWeight: 'bold',
                  display: 'block',
                  padding: '.5rem 1rem',
                  borderRadius: '5px',
                  width: '16rem',
                  margin: '0 .5rem',
                  textAlign: 'center'
                }}
              >
                iCanvas
              </a>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>
      
      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <div style={{ marginTop: '2rem', marginBottom: '1rem' }}>
              <Hero>
                <a className="regular-link" href={`https://www.icanvas.com/canvas-art-prints/artist/remy-disch`} target="_blank">
                  <img src={data.icanvas1.childImageSharp.fixed.srcWebp} alt={`Icanvas`} />
                </a>
              </Hero>
          </div>

          <div style={{ marginBottom: '1rem' }}>
            <Hero>
              <a className="regular-link" href={`https://www.icanvas.com/canvas-art-prints/artist/remy-disch`} target="_blank">
                <img src={data.icanvas2.childImageSharp.fixed.srcWebp} alt={`Icanvas`} />
              </a>
            </Hero>
          </div>

          <div style={{ marginBottom: '1rem' }}>
            <Hero>
              <a className="regular-link" href={`https://www.icanvas.com/canvas-art-prints/artist/remy-disch`} target="_blank">
                <img src={data.icanvas3.childImageSharp.fixed.srcWebp} alt={`Icanvas`} />
              </a>
            </Hero>
          </div>
        </Inner>
      </IndexWrapper>

      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <Hero>
            <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
              <h3 style={{ marginTop: '5rem' }}>Oeuvres récentes</h3>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>

      {
        artworks.map((item, index) => {
          return (
            <CatalogWrapper 
              className="fullscreen description letter" 
              style={{ marginTop: '4rem' }} 
              key={`page-artwork-${index}`}
            >
              {
                item.prismicId === 'Y2TMrREAAA_ec2Hl' ? (
                  <React.Fragment>
                    <CatalogItem 
                      key={`page-artwork-${index}`} 
                      currentArtwork={{ document: [item]}}
                      count={1}
                      elementId={`artwork-${item.prismicId}`}
                      id={item.prismicId}
                      zoomPosition={'left'}
                      hideprice
                      hd
                      cadre
                      responsiveHeight
                    />
                    <p 
                      style={{ 
                        marginTop: '2rem',
                        textAlign: 'center',
                        fontFamily: 'Roboto, sans-serif',
                        color: '#1c252b'
                      }}
                    >
                      <strong>La seconde naissance</strong><br />
                      <span style={{
                        fontSize: '16px',
                        fontWeight: '100',
                        color: '#1c252b'
                      }}>huile sur toile, 79x54cm</span>
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CatalogItem 
                      key={`page-artwork-${index}`} 
                      currentArtwork={{ document: [item]}}
                      count={1}
                      elementId={`artwork-${item.prismicId}`}
                      id={item.prismicId}
                      zoomPosition={'left'}
                      hideprice
                      hd
                      responsiveHeight
                    />
                    <p 
                      style={{ 
                        marginTop: '2rem',
                        textAlign: 'center',
                        fontFamily: 'Roboto, sans-serif',
                        color: '#1c252b'
                      }}
                    >
                      <strong>{item.data.title.text}</strong><br />
                      <span style={{
                        fontSize: '16px',
                        fontWeight: '100',
                        color: '#1c252b'
                      }}>{item.data.type}</span>
                    </p>
                  </React.Fragment>
                )
              }
            </CatalogWrapper>
          )
        })
      }

      <IndexWrapper id={website.skipNavId} style={{paddingTop: 0}}>
        <Inner>
          <Hero>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <a 
                href="https://www.dischr.com/catalog" 
                target="_blank"
                style={{
                  border: '1px solid #b4cfb0',
                  background: '#b4cfb0',
                  color: '#f2f2f2',
                  fontWeight: 'bold',
                  display: 'block',
                  padding: '.5rem 1rem',
                  borderRadius: '5px',
                  width: '16rem',
                  margin: '0 .5rem',
                  textAlign: 'center'
                }}
              >
                Consulter les prix du catalogue
              </a>

              <a 
                href="mailto:davidbraud@dischr.com"
                style={{
                  fontSize: '14px',
                  textDecoration: 'underline',
                  display: 'block',
                  marginTop: '.5rem'
                }}
              >
                contact et informations
              </a>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>

      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <Hero>
            <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>

              <h3 style={{ marginTop: '5rem' }}>Les catalogues</h3>
              <p>
                Vous pouvez accéder au <strong>catalogue des oeuvres 2021-2022</strong> à cette adresse : <br />
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog`} target="_blank">https://www.dischr.com/catalog</a>{` `} <br /><br />
                et au <strong>catalogue fonds d'atelier</strong> à cette adresse : <br />
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog/fonds-d-atelier`} target="_blank">https://www.dischr.com/catalog/fonds-d-atelier</a>{` `}
              </p>
              
              <h3 style={{ marginTop: '5rem' }}>Acquérir des oeuvres</h3>
              <p>
                Si vous souhaitez acquérir et/ou vous informer de la disponibilité des oeuvres, 
                je vous invite à me contacter directement en utilisant l'adresse email et le numéro de téléphone en pied de page. 

              </p>
              <p>
                Vous pouvez également acquérir des oeuvres sur les site de notre partenaire <a className="regular-link" href="https://www.artsper.com/es/artistes-contemporains/france/102408/remy-disch" target="_blank">Artsper</a>
              </p>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
                <a 
                  href="https://www.icanvas.com/canvas-art-prints/artist/remy-disch" 
                  target="_blank"
                  style={{
                    border: '1px solid #b4cfb0',
                    background: '#b4cfb0',
                    color: '#f2f2f2',
                    fontWeight: 'bold',
                    display: 'block',
                    padding: '.5rem 1rem',
                    borderRadius: '5px'
                  }}
                >
                  Nouveau partenariat avec iCanvas
                </a>
              </div>
              
              <p>
                <span style={{ paddingTop: '3rem', display: 'block' }}>
                  ---
                  <br />(+33) 651 453 881
                  <br /><a className="regular-link" href="mailto: davidbraud@dischr.com">davidbraud@dischr.com</a>
                  <br /><a className="regular-link" href={`https://www.dischr.com`} target="_blank">dischr.com</a>
                </span>
              </p>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>
    </React.Fragment>
  );
};

export default MarsMMXXIII;

export const pageQuery = graphql`
  query MarsMMXXIII {
    banner: file(name: {eq: "remy-disch-r1341-RYD28"}) {
      childImageSharp {
        fixed(width: 1000, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    icanvas1: file(name: {eq: "icanvas1"}) {
      childImageSharp {
        fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    icanvas2: file(name: {eq: "icanvas2"}) {
      childImageSharp {
        fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    icanvas3: file(name: {eq: "icanvas3"}) {
      childImageSharp {
        fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    artworks: allPrismicArtwork(
        filter: {
            prismicId: {
                in: [
                    "Y8llHBEAACwAbbG5", 
                    "Y7WSpxIAAC0Ar0g6", 
                    "Y4ntdBIAACsAZ3TP", 
                    "Y3dtnBEAACoAapTP", 
                ]
            }
        }, 
        sort: {
            order: DESC, 
            fields: first_publication_date
        }) {
      nodes {
        prismicId
        data {
          meta_description
          meta_title
          title {
            text
          }
          drouot_estimate
          catalog_fullscreen
          type
          available
          on_demand
          price
          item {
            alt
            localFile {
              childImageSharp {
                fixed(height: 800, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          cadre {
            alt
            localFile {
              childImageSharp {
                fixed(height: 800, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image_hd {
            localFile {
              childImageSharp {
                original {
                  height
                  src
                  width
                }
              }
            }
          }
          categories {
            ... on PrismicArtworkDataCategories {
              category {
                uid,
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
          year {
            ... on PrismicArtworkDataYear {
              uid
            }
          }
        }
      }
    }
  }
`
